<template>
  <div class="min-h-screen bg-slate-900 text-white">
    <!-- Navigation -->
    <nav class="bg-slate-800 p-4 sticky top-0 z-10">
      <div class="container mx-auto flex justify-between items-center">
        <img src="./assets/logo-dark.png" alt="logo" class="h-16"/>
        <!-- <h1 class="text-2xl font-bold">iStacked</h1> -->
        
        <!-- Hamburger menu for mobile -->
        <button @click="toggleMenu" class="md:hidden text-white focus:outline-none">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path v-if="!isMenuOpen" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"></path>
            <path v-else stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        
        <!-- Desktop menu -->
        <div class="hidden md:flex space-x-4">
          <a v-for="item in navItems" :key="item" :href="`#${item.toLowerCase()}`" class="hover:text-violet-400">
            {{ item }}
          </a>
        </div>
      </div>
    </nav>

    <!-- Mobile menu -->
    <transition
      enter-active-class="transition duration-300 ease-out"
      enter-from-class="transform -translate-y-full opacity-0"
      enter-to-class="transform translate-y-0 opacity-100"
      leave-active-class="transition duration-200 ease-in"
      leave-from-class="transform translate-y-0 opacity-100"
      leave-to-class="transform -translate-y-full opacity-0"
    >
      <div v-if="isMenuOpen" class="md:hidden bg-slate-800 absolute mt-4 top-16 left-0 right-0 z-20">
        <div class="container mx-auto px-4 py-2">
          <a 
            v-for="item in navItems" 
            :key="item" 
            :href="`#${item.toLowerCase()}`" 
            @click="closeMenu"
            class="block py-2 hover:text-violet-400"
          >
            {{ item }}
          </a>
        </div>
      </div>
    </transition>

    <!-- Hero Section -->
    <section class="py-20">
      <div class="container mx-auto px-4 flex flex-col md:flex-row items-center">
        <div class="md:w-1/2 text-center md:text-left mb-8 md:mb-0">
          <h2 class="text-4xl md:text-5xl font-bold mb-6">Smart Solutions for Thriving Businesses</h2>
          <p class="text-xl md:text-2xl mb-8">Streamline Your Operations and Boost Your Online Presence with Our Cutting-Edge Software Solutions</p>
          <button class="bg-violet-800 hover:bg-violet-700 text-white font-bold py-2 px-4 rounded-full transition duration-300">
            Get Started
          </button>
        </div>
        <div class="md:w-1/2 flex justify-center">
          <img src="./assets/desktop/building-websites.svg" alt="design" width="600px">
        </div>
      </div>
    </section>

    <!-- Services Section -->
    <section id="services" class="py-16">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl font-bold mb-12 text-center">Our Services</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div v-for="service in services" :key="service.title" class="bg-slate-800 p-6 rounded-lg">
            <component :is="service.icon" class="w-12 h-12 mb-4 text-violet-400" />
            <h3 class="text-xl font-semibold mb-2">{{ service.title }}</h3>
            <p>{{ service.description }}</p>
          </div>
        </div>
      </div>
    </section>

    <!-- Pricing Section -->
    <section id="pricing" class="py-16">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl font-bold mb-12 text-center">Pricing Plans</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div v-for="plan in pricingPlans" :key="plan.title" class="bg-slate-800 p-6 rounded-lg text-center">
            <h3 class="text-xl font-semibold mb-2">{{ plan.title }}</h3>
            <p class="text-4xl font-bold mb-4">${{ plan.price }}<span class="text-sm font-normal">/mo</span></p>
            <ul class="mb-6 space-y-2">
              <li v-for="feature in plan.features" :key="feature">{{ feature }}</li>
            </ul>
            <button class="bg-violet-800 hover:bg-violet-700 text-white font-bold py-2 px-4 rounded-full transition duration-300">
              Choose Plan
            </button>
          </div>
        </div>
      </div>
    </section>

    <!-- About Us Section -->
    <section id="about" class="py-16">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl font-bold mb-8 text-center">About Us</h2>
        <div class="max-w-3xl mx-auto text-center">
          <p class="mb-6">
            iStacked is a leading web development company dedicated to providing smart solutions for thriving businesses. 
            With our team of expert developers and cutting-edge technologies, we help businesses streamline their operations 
            and boost their online presence.
          </p>
          <p>
            Our mission is to empower businesses with innovative software solutions that drive growth, 
            increase efficiency, and deliver exceptional user experiences. We're passionate about technology 
            and committed to helping our clients succeed in the digital landscape.
          </p>
        </div>
      </div>
    </section>

    <!-- Contact Us Section -->
    <section id="contact" class="py-16">
      <div class="container mx-auto px-4">
        <h2 class="text-3xl font-bold mb-8 text-center">Contact Us</h2>
        <form @submit.prevent="submitForm" class="max-w-lg mx-auto">
          <div class="mb-4">
            <label for="name" class="block mb-2">Name</label>
            <input v-model="form.name" type="text" id="name" required class="w-full px-3 py-2 bg-slate-800 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500">
          </div>
          <div class="mb-4">
            <label for="email" class="block mb-2">Email</label>
            <input v-model="form.email" type="email" id="email" required class="w-full px-3 py-2 bg-slate-800 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500">
          </div>
          <div class="mb-4">
            <label for="message" class="block mb-2">Message</label>
            <textarea v-model="form.message" id="message" required rows="4" class="w-full px-3 py-2 bg-slate-800 rounded-md focus:outline-none focus:ring-2 focus:ring-violet-500"></textarea>
          </div>
          <button type="submit" class="bg-violet-800 hover:bg-violet-700 text-white font-bold py-2 px-4 rounded-full transition duration-300 w-full">
            Send Message
          </button>
        </form>
      </div>
    </section>

    <!-- Footer -->
    <footer class="bg-slate-800 py-8">
      <div class="container mx-auto px-4 text-center">
        <p>&copy; {{ new Date().getFullYear() }} iStacked. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { Code, Server, Globe, Database, Cloud, Lock } from 'lucide-vue-next'

const navItems = ['Services', 'Pricing', 'About', 'Contact']
const isMenuOpen = ref(false)

const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth'})
}

const toggleMenu = () => {
  scrollToTop();
  isMenuOpen.value = !isMenuOpen.value
}

const closeMenu = () => {
  isMenuOpen.value = false
}


const services = [
  { title: 'Web Development', icon: Code, description: 'Custom web applications tailored to your business needs.' },
  { title: 'Cloud Solutions', icon: Cloud, description: 'Scalable and secure cloud infrastructure for your applications.' },
  { title: 'API Integration', icon: Server, description: 'Seamless integration of third-party APIs and services.' },
  { title: 'E-commerce Solutions', icon: Globe, description: 'Robust online stores with secure payment gateways.' },
  { title: 'Database Management', icon: Database, description: 'Efficient data storage and retrieval solutions.' },
  { title: 'Cybersecurity', icon: Lock, description: 'Protect your digital assets with our security solutions.' },
]

const pricingPlans = [
  {
    title: 'Basic',
    price: 99,
    features: ['1 Project', '5 Pages', 'Basic Support', '1 Month Free Updates']
  },
  {
    title: 'Pro',
    price: 199,
    features: ['3 Projects', '10 Pages', 'Priority Support', '3 Months Free Updates']
  },
  {
    title: 'Enterprise',
    price: 399,
    features: ['Unlimited Projects', 'Unlimited Pages', '24/7 Support', '1 Year Free Updates']
  }
]

const form = ref({
  name: '',
  email: '',
  message: ''
})

const submitForm = () => {
  // Here you would typically send the form data to a server
  console.log('Form submitted:', form.value)
  alert('Thank you for your message! We will get back to you soon.')
  form.value = { name: '', email: '', message: '' }
}
</script>