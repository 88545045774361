<template>
  <div
    class="container max-w-6xl mx-auto  bg-slate-900 text-zinc-300 h-max-screen px-6 py-6 center"
  >
    <section id="hero">
      <div class="container flex flex-col-reverse mx-auto p-6 lg:flex-row">
        <div class="flex flex-col space-y10 mb-44 lg:mt-16 lg:w-1/2 xl:mb-52">
          <h1
            class="text-5xl font-bold text-center lg:text-6xl lg:max-w-md lg:text-left"
          >
            Smart Solutions for Thriving Businesses
          </h1>
          <p
            class="text-2xl text-center text-gray-400 lg:max-w-md lg:text-left"
          >
            Streamline Your Operations and Boost Your Online Presence with Our
            Cutting-Edge Software Solutions
          </p>
        </div>

        <div class="mb-24 mx-auto md:w-180 lg:mb-0 lg:w-1/2">
          <img
            src="../assets/desktop/web-design.svg"
            alt="design"
            width="600px"
          />
        </div>
      </div>
    </section>

    <!-- <section id="about">
      <div class="container flex flex-col-reverse mx-auto p-6 lg:flex-row">
        About Section
      </div>
    </section> -->

    <section id="pricing">
      <div class="container flex items-center mb-10 justify-center min-h-1/2">
        <!-- Inner Container -->
        <div class="flex flex-col my-6 space-y-6 md:space-y-0 md:space-x-6 md:flex-row md:my-0">
          <!-- Col 1 -->
          <div class="bg-slate-700 rounded-xl text-white">
            <!-- Upper Container -->
            <div class="p-8 mx-3 mt-3 rounded-t-xl bg-slate-900">
              <div class="text-center text-4xl font-bold"> Platinum</div>
              <h2 class="mt-10 font-alata text-5xl text-center"></h2>
              <h3 class="mt-2 text-center">$1000 per month</h3>
              <div class="flex justify-center">
                <a href="#" class="inline-block px-10 py-3 my-6 text-center border border-violet-600 rounded-lg duration-200 hover:bg-violet-800 hover:border-violet-800"> Quote</a>
              </div>
            </div>

            <!-- Border -->
            <div class="border-t border-slate-700"></div>

            <!-- Lower Part -->
             <div class="p-8 mx-3 mb-3 rounded-b-xl bg-slate-900">
              <!-- List Conatainer -->
               <div class="flex flex-col space-y-2">
                <!-- List Item 1 -->

                
                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Brand identity development</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Website domain and hosting services</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Responsive designs for Mobile, Tablet, and Desktop</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Integrations with third-party APIs and services</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Monthly updates and feature enhancements</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Advanced SEO optimization </span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Dedicated Account Manager </span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">24/7 Priority Support</span>
                 </div>

               </div>

             </div>
          </div>

          <div class="bg-slate-700 rounded-xl text-white">
            <!-- Upper Container -->
            <div class="p-8 mx-3 mt-3 rounded-t-xl bg-slate-900">
              <div class="text-center text-4xl font-bold"> Gold</div>
              <h2 class="mt-10 font-alata text-5xl text-center"></h2>
              <h3 class="mt-2 text-center">$500 per month</h3>
              <div class="flex justify-center">
                <a href="#" class="inline-block px-10 py-3 my-6 text-center border border-violet-600 rounded-lg duration-200 hover:bg-violet-800 hover:border-violet-800"> Quote</a>
              </div>
            </div>

            <!-- Border -->
            <div class="border-t border-slate-700"></div>

            <!-- Lower Part -->
             <div class="p-8 mx-3 mb-3 rounded-b-xl bg-slate-900">
              <!-- List Conatainer -->
               <div class="flex flex-col space-y-2">
                <!-- List Item 1 -->
                 <div class="flex justify-left">
                  ❌
                  <span class="text-sm ml-1">Brand identity development</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Website domain and hosting services</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Responsive designs for Mobile, Tablet, and Desktop</span>
                 </div>

                 <div class="flex justify-left">
                  ❌
                  <span class="text-sm ml-1">(Limited) Integrations with third-party APIs and services</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Quarterly updates and feature enhancements</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">SEO optimization </span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Shared Account Manager </span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Business Hours Support</span>
                 </div>

               </div>

             </div>
          </div>

          <div class="bg-slate-700 rounded-xl text-white">
            <!-- Upper Container -->
            <div class="p-8 mx-3 mt-3 rounded-t-xl bg-slate-900">
              <div class="text-center text-4xl font-bold"> Silver</div>
              <h2 class="mt-10 font-alata text-5xl text-center"></h2>
              <h3 class="mt-2 text-center">$100 per month</h3>
              <div class="flex justify-center">
                <a href="#" class="inline-block px-10 py-3 my-6 text-center border border-violet-600 rounded-lg duration-200 hover:bg-violet-800 hover:border-violet-800"> Quote</a>
              </div>
            </div>

            <!-- Border -->
            <div class="border-t border-slate-700"></div>

            <!-- Lower Part -->
             <div class="p-8 mx-3 mb-3 rounded-b-xl bg-slate-900">
              <!-- List Conatainer -->
               <div class="flex flex-col space-y-2">
                <!-- List Item 1 -->
                <div class="flex justify-left">
                  ❌
                  <span class="text-sm ml-1">Brand identity development</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Website domain and hosting services</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Responsive designs for Mobile, Tablet, and Desktop</span>
                 </div>

                 <div class="flex justify-left">
                  ❌
                  <span class="text-sm ml-1">Integrations with third-party APIs and services</span>
                 </div>

                 <div class="flex justify-left">
                  ❌
                  <span class="text-sm ml-1">Updates and feature enhancements (Separate Quote)</span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Basic SEO optimization </span>
                 </div>

                 <div class="flex justify-left">
                  ❌
                  <span class="text-sm ml-1">Account Manager </span>
                 </div>

                 <div class="flex justify-left">
                  ✔️
                  <span class="text-sm ml-1">Basic Support (48 - 72 hrs)</span>
                 </div>
               </div>

             </div>
          </div>

        </div>
      </div>
    </section>


    <div class="footer border-t-2 border-gray-400">
      <div class="container max-w-6xl py-10 mx-auto">
        <div
          class="flex flex-col items-center space-y-6 md:flex-row md:space-y-0 justify-between md:items-start"
        >
          <div
            class="flex flex-col items-center space-y-8 md:items-start md:space-y-4"
          >
            
            <div class="h-8 mb-8">
              <img
                src="../assets/logo-dark.png"
                alt="logo"
                class="w-44 md:ml-3"
              />
            </div>

            
            <div
              class="flex flex-col items-center space-y-4 font-bold md:flex-row md:space-y-0 md:space-x-6 md:ml-3"
            >

              <div class="h-10 group">
                <a href="#pricing">Pricing & Features</a>
                <div
                  class="group-hover:border-b group-hover:border-purple-600"
                ></div>
              </div>

              <div class="h-10 group">
                <a href="#contact">Contact</a>
                <div
                  class="group-hover:border-b group-hover:border-purple-600"
                ></div>
              </div>

              <div class="h-10 group">
                <a href="#about">About Us</a>
                <div
                  class="group-hover:border-b group-hover:border-purple-600"
                ></div>
              </div>

            </div>
          </div>

          <!---Social and copyright-->
          <div
            class="flex flex-col items-start justify-between space-y-4 text-gray-500"
          >
            <!--Icons-->
            <div
              class="flex items-center justify-center mb-8 mx-auto space-x-4 md:justify-end md:justify-0"
            >
              <div class="h-8 group">
                <a href="https://www.facebook.com/istacked">
                  <img
                    src="../assets/desktop/icon-facebook.svg"
                    alt=""
                    class="h-6"
                  />
                </a>
              </div>

              <div class="h-8 group">
                <a href="#">
                  <img
                    src="../assets/desktop/icon-twitter.svg"
                    alt=""
                    class="h-6"
                  />
                </a>
              </div>

              <div class="h-8 group">
                <a href="#">
                  <img
                    src="../assets/desktop/icon-pinterest.svg"
                    alt=""
                    class="h-6"
                  />
                </a>
              </div>

              <div class="h-8 group">
                <a href="https://www.instagram.com/istacked.io/">
                  <img
                    src="../assets/desktop/icon-instagram.svg"
                    alt=""
                    class="h-6"
                  />
                </a>
              </div>
            </div>

            <div class="font-bold">
              &copy; 2024 istacked. All Rights Reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent } from 'vue';

const HomeView = defineComponent({
  setup() {
    
  },
  methods: {
  }
})
export default HomeView;
</script>
